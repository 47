import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import ReactTooltip from 'react-tooltip'
import { currencyFormatter, formatDate } from '../utils'
import { useInfoPreviewContext } from '../context/InfoPreviewContext'
import { useQuoteContext } from '../context/QuoteContext'
import Loader from '../components/Loader'
import Steps from '../components/Steps'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { FieldValues, useForm } from 'react-hook-form'
import ApiService from '../services/ApiService'
import TipOptions from '../components/payment/TipOptions'
import classNames from 'classnames'
import AcceptModal from '../components/payment/AcceptModal'
import BillingAuthorization from '../components/payment/BillingAuthorization'
import LetterOfCredit from '../components/payment/LetterOfCredit'
import { AcceptModalSubmitProps } from '../types'

const cardStyle = {
    style: {
        base: {
            color: '#333',
            fontSize: '16px',
            '::placeholder': {
                color: '#32325d',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
}

export default function Quote() {
    const { register: registerInfo, handleSubmit: handlePurchaseSubmit } =
        useForm()
    const [loading, setLoading] = useState<boolean>(false)
    const [purchasing, setPurchasing] = useState<boolean>(false)
    const [processing, setProcessing] = useState<boolean>(false)
    const [purchased, setPurchased] = useState<boolean>(false)
    const [purchaseDisabled, setPurchaseDisabled] = useState<boolean>(true)
    const [showTipSection, setShowTipSection] = useState<boolean>(false)
    const [tipAmount, setTipAmount] = useState<number>(0)
    const [showDocuments, setShowDocuments] = useState<boolean>(false)
    const [policyName, setPolicyName] = useState<string | undefined>(undefined)
    const infoPreview = useInfoPreviewContext()?.infoPreview
    const quote = useQuoteContext()?.quote
    const stripe = useStripe()!
    const elements = useElements()!

    const [billingAuthorizationVisible, setBillingAuthorizationVisible] =
        useState<boolean>(false)
    const [billingAuthorizationAccepted, setBillingAuthorizationAccepted] =
        useState<boolean>(false)
    const [billingAuthorizationName, setBillingAuthorizationName] =
        useState<string>('')
    const [billingAuthorizationTimestamp, setBillingAuthorizationTimestamp] =
        useState<string>(new Date().toLocaleString())

    const [letterOfCreditVisible, setLetterOfCreditVisible] =
        useState<boolean>(false)
    const [letterOfCreditAccepted, setLetterOfCreditAccepted] =
        useState<boolean>(false)
    const [letterOfCreditName, setLetterOfCreditName] = useState<string>('')
    const [letterOfCreditTimestamp, setLetterOfCreditTimestamp] =
        useState<string>(new Date().toLocaleString())

    if (quote === undefined) {
        return <Navigate to="/start" />
    }

    if (infoPreview === undefined) {
        return <Navigate to="/start" />
    }

    const total = quote.total

    const cardOnChange = async (event: StripeCardElementChangeEvent) => {
        setPurchaseDisabled(!event.complete)
    }

    const onProcessPayment = async () => {
        setLoading(true)

        try {
            const response = await ApiService.post<{
                name: string
            }>(`/insurance/confirm-purchase/${infoPreview.email}`)

            setLoading(false)
            setProcessing(false)
            setPurchased(true)
            setPolicyName(response.name)
        } catch (error) {
            alert(
                'There was an error generating your policy. Please contact our customer support.'
            )
            setProcessing(false)
        }
    }

    const onPurchase = async (data: FieldValues) => {
        setProcessing(true)

        let stripeClientSecret: string

        try {
            const response = await ApiService.post<string>(
                '/insurance/prepare',
                {
                    email: infoPreview.email,
                    nameOnCard: data.nameOnCard,
                }
            )

            stripeClientSecret = response
        } catch (error) {
            alert(
                'There was an error trying to initiate this payment. Please try again.'
            )
            setProcessing(false)
            return
        }

        try {
            const confirm = await stripe.confirmCardPayment(
                stripeClientSecret,
                {
                    payment_method: {
                        card: elements.getElement(CardElement)!,
                    },
                }
            )

            if (confirm.error !== undefined) {
                switch (confirm.error.code) {
                    case 'incorrect_cvc':
                        alert('Incorrect CVC number. Please try again.')
                        break
                    case 'card_declined':
                        alert(
                            `Your card was declined with the message: ${confirm.error.message} Please try a different card.`
                        )
                        break
                    default:
                        alert(
                            'Please confirm your credit card details and try again.'
                        )
                        break
                }

                setProcessing(false)
                return
            }
        } catch (error) {
            alert(
                'There was an error processing your payment. Please try again.'
            )
            setProcessing(false)
            return
        }

        setLoading(true)

        try {
            const response = await ApiService.post<{
                name: string
            }>(`/insurance/purchase/${infoPreview.email}`)

            setLoading(false)
            setProcessing(false)
            setPurchased(true)
            setPolicyName(response.name)
        } catch (error) {
            alert(
                'There was an error generating your policy. Please contact our customer support.'
            )
            setProcessing(false)
        }
    }

    if (loading) {
        return <Loader />
    }

    if (purchased) {
        return (
            <div className="article article--center">
                <img
                    alt=""
                    src="/assets/thanks.png"
                    style={{ maxWidth: '400px' }}
                />
                {infoPreview.purchaseMethod === 'credit-card' ? (
                    <>
                        <p>
                            <br />
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="button"
                                href="https://g.page/r/CaKzS97DZWf-EB0/review"
                            >
                                Tell us how we did
                            </a>
                            <br />
                            <br />
                        </p>
                        <h1>Congratulations, you're all set!</h1>
                        <p>
                            Your Letter of Credit Number is{' '}
                            <strong>{policyName}</strong>.<br />
                            Please check the email address you provided for
                            additional information and a confirmation for your
                            records.
                        </p>
                        <h2>Next Renewal Date</h2>
                        <h3>
                            {new Date(
                                infoPreview.renewalDate
                            ).toLocaleDateString()}
                        </h3>
                        <br />
                        <br />
                        <p>
                            If you plan to move out before your next renewal
                            date with Lighthouse (above), please notify your
                            property manager as soon as possible to avoid being
                            billed in error.
                        </p>
                    </>
                ) : (
                    <>
                        <h1>Thank you for choosing Lighthouse!</h1>
                        <h2>Your payment is now being processed…</h2>
                        <p>
                            <strong>
                                Please be sure to maintain sufficient funds in
                                your account over the next 5 business days while
                                your payment processes. A Policy ID will be
                                provided via email upon completion.
                            </strong>
                        </p>
                        <p>
                            For more information, please check your email
                            address used to sign up, or contact our Support Team
                            at
                            <br /> (805) 272-0003.
                        </p>
                    </>
                )}
            </div>
        )
    }

    if (showTipSection) {
        return (
            <TipOptions
                onSubmit={(value: number) => {
                    setTipAmount(value)
                    setShowTipSection(false)
                    setPurchasing(true)
                }}
            />
        )
    }

    if (showDocuments) {
        return (
            <div>
                {' '}
                <div style={{ textAlign: 'center' }}>
                    <h2>
                        Please review and sign the following required documents:
                    </h2>
                    <div
                        style={{
                            color: 'red',
                            margin: '20px 0 0 0',
                            fontSize: '14px',
                        }}
                    >
                        You won't be able to proceed without signing these
                        documents.
                    </div>
                    <div className="documents">
                        <div
                            className={classNames('document', {
                                'document--checked':
                                    billingAuthorizationAccepted,
                            })}
                            onClick={() => setBillingAuthorizationVisible(true)}
                        >
                            <div className="document_checkmark"></div>
                            <div className="document_title">
                                Automatic Billing Authorization
                            </div>
                        </div>
                        <div
                            className={classNames('document', {
                                'document--checked': letterOfCreditAccepted,
                            })}
                            onClick={() => setLetterOfCreditVisible(true)}
                        >
                            <div className="document_checkmark"></div>
                            <div className="document_title">
                                Rental Letter of Credit
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <AcceptModal
                        accepted={billingAuthorizationAccepted}
                        visible={billingAuthorizationVisible}
                        name={billingAuthorizationName}
                        timestamp={billingAuthorizationTimestamp}
                        correctName={`${infoPreview.firstName} ${infoPreview.lastName}`}
                        onSubmit={async (data: AcceptModalSubmitProps) => {
                            try {
                                await ApiService.post<string>(
                                    '/insurance/sign-document',
                                    {
                                        documentId: 1,
                                        unitInsuredId: infoPreview.id,
                                        name: data.name,
                                    }
                                )
                            } catch (error) {
                                alert('There was an error. Please try again.')
                                return
                            }

                            setBillingAuthorizationAccepted(true)
                            setBillingAuthorizationVisible(false)
                            setBillingAuthorizationName(data.name)
                            setBillingAuthorizationTimestamp(data.timestamp)
                        }}
                        onClose={() => setBillingAuthorizationVisible(false)}
                    >
                        <BillingAuthorization />
                    </AcceptModal>
                    <AcceptModal
                        accepted={letterOfCreditAccepted}
                        visible={letterOfCreditVisible}
                        name={letterOfCreditName}
                        timestamp={letterOfCreditTimestamp}
                        correctName={`${infoPreview.firstName} ${infoPreview.lastName}`}
                        onSubmit={async (data: AcceptModalSubmitProps) => {
                            try {
                                await ApiService.post<string>(
                                    '/insurance/sign-document',
                                    {
                                        documentId: 2,
                                        unitInsuredId: infoPreview.id,
                                        name: data.name,
                                    }
                                )
                            } catch (error) {
                                alert('There was an error. Please try again.')
                                return
                            }

                            setLetterOfCreditAccepted(true)
                            setLetterOfCreditVisible(false)
                            setLetterOfCreditName(data.name)
                            setLetterOfCreditTimestamp(data.timestamp)
                        }}
                        onClose={() => setLetterOfCreditVisible(false)}
                    >
                        <LetterOfCredit data={infoPreview} quote={quote} />
                    </AcceptModal>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <button
                        onClick={() => {
                            setShowDocuments(false)
                            setPurchasing(true)
                        }}
                        disabled={
                            !(
                                billingAuthorizationAccepted &&
                                letterOfCreditAccepted
                            )
                        }
                        className="button"
                    >
                        Complete Purchase
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            <Steps active={3} />
            <div className="quote">
                <div className="quote__amount">
                    <span>{currencyFormatter.format(total)}</span>
                </div>
                <div className="quote__amount__info">
                    Total amount, no monthly fees.
                </div>
                <dl className="list">
                    <dt>Credit Amount:</dt>
                    <dd>
                        {currencyFormatter.format(infoPreview.coverageAmount)}
                    </dd>
                    <dt>Start Date:</dt>
                    <dd>{formatDate(infoPreview.coverageStartDate)}</dd>
                    <dt>Next Renewal Date:</dt>
                    <dd>{formatDate(infoPreview.renewalDate)}</dd>
                </dl>
                {infoPreview.renewalTerm === 'Annual' ? (
                    <p className="quote__info">
                        For just <strong>${quote.quote}</strong>, you are
                        guaranteeing a Line of Credit up to{' '}
                        <strong>
                            {currencyFormatter.format(
                                infoPreview.coverageAmount
                            )}
                        </strong>{' '}
                        for your Landlord to potentially call on during the
                        period outlined above. This coverage protects your
                        Landlord from any unpaid rent and/or damages beyond
                        normal wear & tear related to your lease. Please note
                        that you remain responsible for these expenses and must
                        renew coverage with Lighthouse at the beginning of each
                        new lease term.
                        <br />
                        <br />
                        There are no monthly fees. Renewals are automatically
                        processed using the payment method provided.
                        <br />
                        See our{' '}
                        <a href="/faq" rel="noreferrer" target="_blank">
                            <strong>Tenant FAQ</strong>
                        </a>{' '}
                        for more information on coverage.
                    </p>
                ) : (
                    <p className="quote__info">
                        Your property manager has converted your Security
                        Deposit Alternative through Lighthouse to a short-term
                        plan.
                        <br />
                        <br />
                        The amount you see above is for{' '}
                        <strong>three (3) additional months</strong> of
                        coverage.
                        <br />
                        <br />
                        Going forward, your coverage will{' '}
                        <strong>automatically</strong> renew three months at a
                        time using the payment information on file. This will
                        continue until you either sign a new lease or move out.
                        You are responsible for maintaining up to date payment
                        information at all times.
                        <br />
                        <br />
                        These fees are non-refundable, original terms still
                        apply.
                        <br />
                        <br />
                        See{' '}
                        <a href="/faq" target="_blank">
                            Tenant FAQ
                        </a>{' '}
                        for more information.
                    </p>
                )}

                <dl
                    className="list"
                    style={{ maxWidth: '500px', margin: '0 auto 50px auto' }}
                >
                    <dt>Issuing Fee:</dt>
                    <dd>{currencyFormatter.format(quote.quote)}</dd>
                    <dt>Processing Fee: </dt>
                    <dd>{currencyFormatter.format(quote.billingFee)}</dd>
                    {quote.lateFee !== null && quote.lateFee > 0 && (
                        <>
                            <dt>Late Fee: </dt>
                            <dd>{currencyFormatter.format(quote.lateFee)}</dd>
                        </>
                    )}
                    <dt>
                        <strong>Total:</strong>
                    </dt>
                    <dd>{currencyFormatter.format(total)}</dd>
                </dl>

                <ReactTooltip html={true} />

                {infoPreview.renewalTerm === 'Annual' && (
                    <p className="quote__info">
                        By purchasing coverage through Lighthouse, you agree to
                        abide by our terms and conditions outlined in the{' '}
                        <a
                            rel="noreferrer"
                            href="https://media.mylighthouse.co/docs/tenant-acknowledgment-agreement.pdf"
                            target="_blank"
                        >
                            Tenant Acknowledgement Agreement
                        </a>{' '}
                        and confirm that you are the rightful owner of the
                        payment method provided, hereby authorizing this
                        transaction and waiving your right to dispute this
                        payment as fraudulent at any time. If payment is
                        refunded by Lighthouse for any reason, processing fees
                        will not be included. Please read these terms carefully
                        before making a purchase, as they outline the rights and
                        responsibilities of all parties involved. Your purchase
                        constitutes acceptance of these terms and conditions,
                        forming a legally binding agreement between you and our
                        company.
                    </p>
                )}
                <p>
                    <button
                        onClick={() => {
                            setShowDocuments(true)
                        }}
                        className="button"
                    >
                        Continue
                    </button>

                    {/*}
                    <button
                        onClick={() => {
                            setPurchasing(true)
                        }}
                        className="button"
                    >
                        Purchase
                    </button>

                    {*/}

                    {/*}
                    <button
                        onClick={() => {
                            setShowTipSection(true)
                        }}
                        className="button"
                    >
                        Continue
                    </button>
                    {*/}
                </p>

                <div
                    className="overlay"
                    style={{ display: purchasing ? 'block' : 'none' }}
                >
                    <div className="purchase">
                        {infoPreview.purchaseMethod === 'credit-card' ? (
                            <>
                                <h2>
                                    Please enter your credit card information
                                </h2>
                                <form
                                    onSubmit={handlePurchaseSubmit(onPurchase)}
                                >
                                    <fieldset className="fieldset">
                                        <strong>
                                            {currencyFormatter.format(total)}
                                        </strong>
                                    </fieldset>
                                    <fieldset className="fieldset">
                                        <label
                                            style={{ textAlign: 'left' }}
                                            className="label"
                                        >
                                            Name on card *
                                        </label>
                                        <input
                                            required={true}
                                            type="text"
                                            className="input"
                                            {...registerInfo('nameOnCard')}
                                        />
                                    </fieldset>
                                    <fieldset className="fieldset">
                                        <CardElement
                                            options={cardStyle}
                                            onChange={cardOnChange}
                                        />
                                    </fieldset>
                                    <fieldset className="fieldset">
                                        <p
                                            style={{
                                                fontSize: '0.8rem',
                                                marginBottom: '15px',
                                            }}
                                        >
                                            This transaction will appear on your
                                            bank statement as
                                            <br />
                                            <strong>
                                                "Lighthouse Deposits."
                                            </strong>
                                        </p>
                                        <button
                                            className="button"
                                            disabled={
                                                purchaseDisabled || processing
                                            }
                                        >
                                            {processing
                                                ? 'Please wait...'
                                                : 'Complete Purchase'}
                                        </button>
                                    </fieldset>
                                    <fieldset className="fieldset">
                                        <span
                                            className="cancel"
                                            onClick={() => {
                                                setPurchasing(false)
                                            }}
                                        >
                                            Cancel
                                        </span>
                                    </fieldset>
                                </form>
                            </>
                        ) : (
                            <>
                                <h2>Please confirm your purchase</h2>
                                <form onSubmit={onProcessPayment}>
                                    <fieldset className="fieldset">
                                        <strong>
                                            {currencyFormatter.format(total)}
                                        </strong>
                                    </fieldset>
                                    <fieldset>
                                        <p
                                            style={{
                                                fontSize: '0.8rem',
                                                marginBottom: '15px',
                                            }}
                                        >
                                            This transaction will appear on your
                                            bank statement as,{' '}
                                            <strong>
                                                "Lighthouse Deposits."
                                            </strong>
                                        </p>
                                        <button
                                            className="button"
                                            disabled={processing}
                                        >
                                            {processing
                                                ? 'Please wait...'
                                                : 'Confirm Purchase'}
                                        </button>
                                    </fieldset>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
